.App {
  flex: 1;

  min-width: 0;
  display: flex;
}

.ibutton {
  width: 50px;
  height: 50px;
  background-color: #00000099;
  border: 4px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 150%;
  color: white;
  cursor: pointer;
  &:hover {
    color: orange;
  }
  &.open {
    border-color: orange;
  }
}

.mobileInfoView {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 8px;
  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 10;
    font-family: sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

.loadingScreen {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  background-color: black;
}
